import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SimpleFooter from '../../../components/footer/SimpleFooter';
import SimpleNavbar from '../../../components/navbar/SimpleNavbar';
import { CUSTOM_EVENT, pushEvent } from '../../../configs/clevertap';
import Button from '../../../components/button/Button';
import FileDownload from '../../../components/referralContents/FileDownload';
import SEO from '../../../SEO';
import GTM_TRACKERS from '../../../../constants/gtm-trackers';

export default function ThankYou() {
  const [isShowModalDownload, setIsShowModalDownload] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current) return;

    ref.current.focus();
  }, [isShowModalDownload]);

  const handleDesktopButton = () => {
    setIsShowModalDownload(true);
    pushEvent(CUSTOM_EVENT.DOWNLOAD_PANDUAN_PROGRAM);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      setIsShowModalDownload(false);
    }
  };

  return (
    <>
      <SEO
        title="DepositoBPR Referral Program | Thank You!"
        metaDescription="DepositoBPR Referral Program #AjakTemanDapatBonus Thank You Page"
      />
      <div className="flex flex-col items-center">
        <div className="w-full bg-blue-light40">
          <SimpleNavbar />
          <main className="flex flex-col gap-y-4 md:mt-4 md:mb-16 relative">
            <div className="hidden md:block absolute left-0 top-72 h-full">
              <div className="w-full sticky top-0 z-0">
                <StaticImage
                  src="../../../images/referral/big-vector.svg"
                  alt="big-vector"
                />
              </div>
            </div>
            <div className="hidden md:block absolute left-0 h-full">
              <div className="sticky top-0 z-0">
                <StaticImage
                  src="../../../images/referral/coins.webp"
                  alt="coins"
                  className="w-screen"
                />
              </div>
            </div>
            <article className="relative flex flex-col items-center gap-y-2 py-2">
              <StaticImage
                src="../../../images/referral/ajak-teman.webp"
                alt="ajak-teman"
                objectFit="contain"
                className="w-64 z-30"
              />
              <div className="absolute top-5 z-20">
                <StaticImage
                  src="../../../images/referral/clouds.webp"
                  alt="clouds"
                  objectFit="contain"
                  className="w-64"
                />
              </div>
              <div className="flex flex-col items-center z-30">
                <h3 className="font-normal text-xl text-white text-center lg:text-2xl">
                  <span className="font-bold">Raih Penghasilan</span> Hingga
                </h3>
                <h2 className="font-bold text-3xl text-white text-center lg:text-4xl">Jutaan Rupiah</h2>
              </div>
            </article>
            <article className="flex flex-col items-center bg-white rounded-lg p-4 gap-y-4 z-10 m-4 md:mx-20 lg:mx-64">
              <h2 className="text-2xl font-bold text-blue-light40 text-center">Terima Kasih Sudah Mendaftar</h2>
              <StaticImage
                src="../../../images/referral/register/thank-you.webp"
                alt="thank-you"
                objectFit="contain"
                className="w-40"
              />
              <p className="text-sm md:mx-40 text-center">
                Silahkan klik tombol di bawah ini untuk download panduan program & marketing toolsnya.
              </p>
            </article>
            <div
              className={`md:hidden mx-4 mb-4 flex justify-center ${GTM_TRACKERS.REFERRAL.DOWNLOAD_PANDUAN_PROGRAM}`}
            >
              <Button
                onClick={() => pushEvent(CUSTOM_EVENT.DOWNLOAD_PANDUAN_PROGRAM)}
                isInternal
                text="Download Panduan Program"
                href="/referral/guide-download"
                className="w-80 py-3 text-center"
              />
            </div>
            <div className={`hidden md:flex md:justify-center md:relative md:z-20 ${GTM_TRACKERS.REFERRAL.DOWNLOAD_PANDUAN_PROGRAM}`}>
              <button
                type="button"
                onClick={handleDesktopButton}
                // eslint-disable-next-line max-len
                className="hidden md:block py-3 w-80 text-center text-sm text-blue-light40 font-semibold bg-gradient-to-b from-yellow-400 to-yellow-500 shadow-[0_6px_10px_0_rgba(0,0,0,0.25)] rounded-xl border-[1px] border-secondary-100 cursor-pointer"
              >
                Download Panduan Program
              </button>
            </div>
          </main>
          <SimpleFooter />
        </div>

        {isShowModalDownload && (
          <div
            ref={ref}
            className="flex flex-row bg-black-dark items-center justify-center backdrop-blur-xs fixed w-screen h-screen left-0 top-0 z-50"
            onKeyDown={handleKeyDown}
            role="presentation"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
          >
            <FileDownload onClose={() => setIsShowModalDownload(false)} />
          </div>
        )}
      </div>
    </>
  );
}
